import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Feed from '../views/Feed.vue'
import Form from '../views/Form.vue'
import AddLesson from '../views/AddLesson.vue'
import List from '../views/List.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Lesson from '../views/Lesson.vue'
import LessonDetail from '../views/LessonDetail.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/feed',
    name: 'Feed',
    component: Feed
  },
  {
    path: '/form',
    name: 'Form',
    component: Form
  },
  {
    path: '/addlesson',
    name: 'AddLesson',
    component: AddLesson
  },
  {
    path: '/list',
    name: 'List',
    component: List
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/lesson/:id',
    name: 'Lesson',
    component: Lesson
  },
  {
    path: '/video/:uuid',
    name: 'LessonDetail',
    component: LessonDetail
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // 判断本地是否有isLogin
  // const isLogin = localStorage.getItem('isLogin')
  const isLogin = localStorage.getItem('isLogin')
  console.log('现在开始判断路由',isLogin)
  if (to.name == 'Login') { //判断是否进入的login页
      if (isLogin) {  //判断是否登陆
        next({ name: 'Home'});  //已登录，跳转首页（a页面）
      } else {
        next();  //没登录，继续进入login页
      }
  } else { //如果进入的非login页
      if (isLogin) {   //同样判断是否登陆
        next();  //已登录，正常进入
      } else {
        next({ name: 'Login'});  //没登录，跳转到login页
      }
  }
})


export default router
