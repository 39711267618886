<template>
    <div>
        register
    </div>
</template>
<script>
export default {
    name:"register"
}
</script>
<style>
    
</style>