<template>
    <div>
        <el-skeleton 
            :rows="5" 
            animated 
            :loading="!hasLesson"
            :throttle="500"
         />
        <ul>
            <li v-for="lesson in lessons[lesson_id]" :key="lesson.id"><router-link :to="'/video/'+lesson.uuid">{{lesson.title}}</router-link></li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'Lesson',
    props: ['id'],
    computed:{
        lessons(){
            return this.$store.state.lessons
        },
        hasLesson(){
            if(this.$store.state.lessons.length>0){
                return true
            }else{
                return false
            }
        },
        // 动态路由获取lesson_id
        lesson_id(){
            return this.$route.params.id;
        }
    },
    mounted(){
        // 加载课程数据
        console.log('开始请求数据');
        setTimeout(
            ()=>{
                this.$store.dispatch('getLesson', {id:this.lesson_id})
            },1000

        )
    }
}
</script>
<style>
    
</style>