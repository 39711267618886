<template>
    <div>
        <el-col :span="12" :offset="6">
            <el-input type="text" name="username" v-model="username" id=""></el-input>
            <el-input type="password" name="password" v-model="password" id=""></el-input>
            <el-input type="submit" @click="login">登陆</el-input>
        </el-col>
        <div style="height:10000px"></div>
    </div>
</template>
<script>
import axios from '../request/req';
import { ElMessage } from 'element-plus';
import router from '../router'
export default {
    name:"Login",
    data(){
        return {
            username: '',
            password: ''
        }
    },
    methods:{
        async login(){
            const data = await axios.post('/login',{
                username:this.username,
                password:this.password
            })
            if(data.data.status){
                // 登陆成功
                ElMessage.success({
                    message: data.data.message,
                    type: 'success'
                });
                // token记录到本地
                const token = data.data.token;
                localStorage.setItem('token',token);
                localStorage.setItem('isLogin',1);
                // 跳转
                router.push({ name: 'Home' })
                // 修改state中isLogin的值
                this.$store.commit('changeLogin',{isLogin:1})
            }else{
                // 登陆失败
                ElMessage.error(data.data.message);
            }
        }
    }
}
</script>
<style>
    
</style>