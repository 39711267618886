<template lang="">
    <div>
        {{item.id}}
        <br>
        {{item.attributes.title}}{{item.attributes.postContent}}
        <br>
        {{item.attributes.createdAt}}
        <br>
        浏览次数{{item.attributes.viewCount}}
        <hr>
    </div>
</template>
<script>

export default {
    name: 'FeedItem',
    props:{
        item: Object
    }
}
</script>
<style lang="">
    
</style>