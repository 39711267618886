<template>
    <div>
        增加课程
        <br>
        <el-input type="text" name="title" v-model="title" placeholder="title"></el-input>
        <br>
        <el-input type="text" name="author" v-model="author" placeholder="author"></el-input>
        <br>
        <el-input type="text" name="oss_folder" v-model="oss_folder" placeholder="文件目录"></el-input>
        <br>
        <textarea name="intro" id="" cols="30" rows="10" v-model="intro"></textarea>
        <br>
        <el-button @click="addLessonHandle" class="">提交</el-button>
    </div>
</template>
<script>
export default {
    name: 'AddLesson',
    data() {
        return {
            title:'',
            author: '',
            oss_folder: '',
            intro: ''
        }
    },
    methods:{
        addLessonHandle(){
            this.$store.dispatch('addLesson',{
                title: this.title,
                author: this.author,
                intro: this.intro,
                oss_folder: this.oss_folder
            })
        }
    }
}
</script>
<style>
    
</style>