<template>
    <div>
        <ul>
            <li v-for="item in list" :key="item.id"><router-link :to="'/lesson/'+item.id">{{item.title}}</router-link></li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'Lesson',
    computed:{
        list(){
            return this.$store.state.list
        }
    },
    mounted(){
        const payload = {page:1}
        this.$store.dispatch('getList',payload)
    }
}
</script>
<style>
    
</style>