<template>
    <div>
        <div>{{data.title}}</div>
        <div style="width:500px; height: 350px">
            <!-- <video :src="url" controls width="500"></video> -->
            <vue-plyr :options="options" v-if="url">
                <video
                    controls
                    crossorigin
                    playsinline
                    data-poster="http://www.vvenglish.com/code.jpg"
                >
                    <source
                    size="720"
                    :src="url"
                    type="video/mp4"
                    />
                </video>
            </vue-plyr>
        </div>
    </div>
</template>
<script>
import axios from '../request/req';
export default {
    name:'LessonDetail',
    data(){
        return {
            data:{},
            url:'',
            options:{}
        }
    },
    props: ['uuid'],
    computed:{
        myUuid(){
            return this.$route.params.uuid;
        }
    },
    methods:{

    },
    async created(){
        const data = await axios.get('/video/'+this.myUuid);
        this.data = data.data.data;
        this.url = data.data.data.url;
    },
    mounted() {
    },
}
</script>
<style>
    
</style>