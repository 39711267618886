<template>
<el-form label-width="80px">
    <el-form-item label="title">
        <el-input v-model="title"></el-input>
    </el-form-item>
    {{myheaders}}
    <el-form-item label="选择课程">
        <el-select @change="selectLesson"  v-model="lesson_id" placeholder="请选择课程">
        <el-option v-for="lesson in lessons" :key="lesson.id" :label="lesson.title" :value="lesson.id"></el-option>
        </el-select>
    </el-form-item>
    <el-form-item label="lesson_id">
        <el-input v-model="lesson_id" disabled></el-input>
    </el-form-item>
    <el-form-item label="oss_folder">
        <el-input v-model="ossfolder" disabled></el-input>
    </el-form-item>
    <el-upload
        v-if="lesson_id" 
        action="/api/v1/upload/stream" 
        :headers=myheaders
        :on-success="successCallback"
        :on-progress="progressHandle"
        :on-error="errorHandle"
        :data={ossfolder}
        >
        <el-button size="small" type="primary">点击上传</el-button>
        <template #tip>
            <div class="el-upload__tip">
            只能上传 jpg/png 文件，且不超过 500kb
            </div>
        </template>
    </el-upload>
    <el-form-item  v-if="upload_end">
        <el-input v-model="videoUrl" disabled></el-input>
    </el-form-item>
    
  <el-form-item>
    <el-button type="primary" @click="createVideo">立即创建</el-button>
    <el-button>取消</el-button>
  </el-form-item>
</el-form>
</template>
<script>
import axios from '../request/req';
import { ElMessage } from 'element-plus';
export default {
    name: 'Form',
    data(){
        return{
            title:'',
            url:'',
            lesson_id: null,
            videoUrl: '',
            upload_start:false,
            upload_end:false,
            upload_message:'',
            ossfolder:''
        }
    },
    mounted(){
        // if(!this.lessons_id){
            // this.$store.dispatch('getList')
        const payload = {page:1}
        this.$store.dispatch('getList',payload)
        // }
    },
    computed: {
        // 根据全局store计算lessons
        lessons(){
            return this.$store.state.list
        },
        // 计算选中的课程
        selectedLesson(){
            return this.lessons[this.lesson_id-1]
        },
        myheaders(){
            const token =  "Bearer " + this.$store.state.token
            return {
                "Authorization": token
            }
        }
    },
    methods:{
        // 上传进度条
        progressHandle(event, file, fileList){
            console.log('event',event);
            console.log('file',file);
            console.log('fileList',fileList);
        },
        errorHandle(error){
            // 错误消息转成对象
            const myError = JSON.parse(error.message);
            ElMessage.error({
                    message:myError.message
            })
        },
        // 上传完成
        successCallback(response){
            console.log('res',response)
            if(response.status){
                ElMessage.success({
                    message:response.message
                })
            }else{
                ElMessage.error({
                    message:response.message
                })
            }
            this.upload_end = true
            this.message = "✅文件上传成功"
            this.videoUrl = response.url
        },
        
        // upload(){
        //     console.log(file);
        //     // ----- 如果重新上传文件，充值上传的状态值 -----
        //     this.upload_end = false;
        //     // ----- // 如果重新上传文件，充值上传的状态值 -----
        //     this.upload_start = true
        //     const file = this.$refs.fileInt.files[0];
        //     const data = new FormData();
        //     data.append('file', file);
        //     data.append('ossfolder', this.ossfolder);
        //     this.message = "☕️文件上传开始"
        //     axios.post('/upload', data, {
        //         headers: {
        //         'Content-Type': 'multipart/form-data',
        //         },
        //     }).then(res => {
        //         console.log('✅文件上传成功', res);
        //         setTimeout(
        //             ()=>{
        //                 this.upload_end = true
        //                 this.message = "✅文件上传成功"
        //                 this.videoUrl = res.data.url
        //             },
        //             1000
        //         )
        //     }).catch(err => {
        //         console.log(err);
        //         this.upload_end = true
        //         this.message = "❌上传失败"
        //     });

        // },
        // 创建一条记录
        createVideo(){
            const data = {
                title: this.title,
                url: this.videoUrl,
                lesson_id: parseInt(this.lesson_id)
            };
            axios.post('/video/create',data).then((res)=>{
                console.log('上传成功',res.data)
                if(res.data.status){
                    alert('增加成功')
                    this.reset()
                }else{
                    alert(res.data.message)
                }
                
            })
            // this.$store.dispatch({
            //     type:'createVideo',
            //     payload: data
            // });
        },
        // 监听下拉框，计算出ossfolder
        selectLesson(){
            if(this.lesson_id){
                this.ossfolder = this.selectedLesson.oss_folder
            }
        },
        // 重置表单
        reset(){
            this.title='';
            this.url='';
            this.lesson_id= null;
            this.videoUrl= '';
            this.upload_start=false;
            this.upload_end=false;
            this.upload_message='';
            this.ossfolder=''
        }
    }
}
</script>
<style>
    
</style>