import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import 'element-plus/lib/theme-chalk/display.css';
// plyr
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'



console.log('process',process.env)
console.log('REQUEST_URL',process.env.REQUEST_URL)

const app = createApp(App)
installElementPlus(app)
app
    .use(store)
    .use(router)
    .use(VuePlyr, {plyr:{}})
    .mount('#app')

