<template>
    <div>
        <feed-item v-for="item in data" :item="item" :key="item.id" />
    </div>
</template>
<script>
import FeedItem from '../components/FeedItem.vue'
export default {
    name: 'Feed',
    components: {
        FeedItem
        
    },
    computed:{
        data () {
            console.log(typeof this.$store.state.feeds.data)
            return this.$store.state.feeds.data
        }
    }
}
</script>
<style>
    
</style>