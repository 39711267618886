import axios from 'axios';
import router from '../router';
import { ElMessage } from 'element-plus';

const request = axios.create({
    baseURL: process.env.REQUEST_URL? process.env.REQUEST_URL + '/api/v1':'https://videoapi.dongshushu.com/api/v1',
    timeout: 5000,
})

// http request 拦截器
request.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token')
        if (token ) { // 判断是否存在token，如果存在的话，则每个http header都加上token
        config.headers.authorization = `Bearer ${token}`  //请求头加上token
        }
        return config
    },
    err => {
        return Promise.reject(err)
    }
)



// 响应拦截
request.interceptors.response.use( response => {
    return response
}, error => {
    // 获取错误状态码，token失效
    const { status } = error.response
    if (status == 401) {
        ElMessage.error('token失效，请重新登录')
        // 清除token
        localStorage.removeItem('token')
        localStorage.removeItem('isLogin')
 
        // 重新跳转到login页面
        router.push('/login')
    }

    return Promise.reject(error)
})


export default request;