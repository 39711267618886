import { createStore } from 'vuex'
import axios from '../request/req'

export default createStore({
  state: {
    isLogin:0,
    token:'',
    feeds:{},
    list:{},
    lessons:[]
    // lessons:[
    //   {
    //     "id": 1,
    //     "title": "",
    //     "url": "http://many-video.oss-cn-beijing.aliyuncs.com/v1/2021_0524_114505_488.png",
    //     "lesson_id": 1,
    //     "chapter_id": null,
    //     "uuid": "6e6183c0-bc42-11eb-8fb1-914a7797771b",
    //     "createdAt": "2021-05-24T03:45:07.000Z",
    //     "updatedAt": "2021-05-24T03:45:07.000Z",
    //     "deletedAt": null
    // }
    // ]
  },
  mutations: {
    // 添加feed数据
    initFeed(state, payload){
      console.log('payload', payload)
      state.feeds = {...payload.feeds}
    },
    // 添加课程到list
    addList(state, payload){
      state.list = {...payload.list}
    },
    // 添加课程的视频列表lessons
    addLessonToStore(state, payload){
      state.lessons[payload.index] = payload.data
    },
    // 修改登陆状态
    changeLogin(state, payload){
      state.isLogin = payload.isLogin
      // 如果传过来token 则修改vuex的全局token
      if(payload.token){
        state.token = payload.token
      }
      
    }
  },
  actions: {
    init({commit}){
      axios.get('/api/threads').then((res)=>{
        console.log('只加载一次内容',res.data)
        commit('initFeed',{feeds: res.data})
      })
    },

    /**
     * 获取列表
     */
     getList({commit}, payload){
      
      axios.get('/list/' + payload.page).then((res)=>{
        console.log('获取列表',res.data.data.rows)
        commit('addList',{list: res.data.data.rows})
      })
    },
    /**
     * 添加课程
     *
     */
     addLesson(context, payload){
      axios.post('/lesson/create', payload).then((res)=>{
        console.log('result',res.data)

      })
     },
    /**
     * 获取课程视频列表
     * @param lesson_id
     */
    getLesson({commit}, payload){
      axios.get('/video/list/' + payload.id).then((res)=>{
        console.log('加载课程',res.data)
        const index =  payload.id;
        const data = res.data.data.rows;
        const payload2 = {index, data};
        commit('addLessonToStore', payload2)
      })
    },
    /**
     * 检查token
     * 
     */
    checkToken({commit}){
      const token = localStorage.getItem('token');
      // state.token = token;
      axios.post('/token/', {token}).then((res)=>{
        if(res.data.status){
          commit('changeLogin', {isLogin:1,token})
          console.log(res.data.message)
        }else{
          commit('changeLogin', {isLogin:0})
          console.log(res.data.message)
        }

      })
    }, 
  },
  modules: {
  }
})
