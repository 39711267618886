<template>
  <el-container>
    <el-aside>
      <el-drawer
      title="菜单"
      v-model="drawer"
      direction="ltr"
      destroy-on-close>
        <!-- 小屏显示 -->
        <el-menu :default-active="1" mode="vertical">
          <el-menu-item index="1"><router-link to="/">Home</router-link></el-menu-item>
          <el-menu-item index="2"><router-link to="/about">About</router-link></el-menu-item>
          <el-menu-item index="3"><router-link to="/feed">Feed</router-link></el-menu-item>
          <el-menu-item index="4"><router-link to="/list">课程</router-link></el-menu-item>
          <el-menu-item index="5"><router-link to="/addlesson">增加课程</router-link></el-menu-item>
          <el-menu-item index="6"><router-link to="/form">表单</router-link></el-menu-item>
          <el-menu-item index="7"><router-link to="/register">注册</router-link></el-menu-item>
          <el-menu-item index="8" v-show="!isLogin"><router-link to="/login">登陆</router-link></el-menu-item>
          <el-menu-item index="9" v-show="isLogin" @click="logout">退出</el-menu-item>
        </el-menu>
        <!-- 小屏显示 -->
      </el-drawer>
    </el-aside>
    <el-header class="hidden-xs-only">
      <!-- 大屏显示 -->
      <el-menu :default-active="1" mode="horizontal">
        <el-menu-item index="1"><router-link to="/">Home</router-link></el-menu-item>
        <el-menu-item index="2"><router-link to="/about">About</router-link></el-menu-item>
        <el-menu-item index="3"><router-link to="/feed">Feed</router-link></el-menu-item>
        <el-menu-item index="4"><router-link to="/list">课程</router-link></el-menu-item>
        <el-menu-item index="5"><router-link to="/addlesson">增加课程</router-link></el-menu-item>
        <el-menu-item index="6"><router-link to="/form">表单</router-link></el-menu-item>
        <el-menu-item index="7"><router-link to="/register">注册</router-link></el-menu-item>
        <el-menu-item index="8" v-show="!isLogin"><router-link to="/login">登陆</router-link></el-menu-item>
        <el-menu-item index="9" v-show="isLogin" @click="logout">退出</el-menu-item>
      </el-menu>
      <!-- 大屏显示 -->
    </el-header>
    <el-main>
      <el-row type="flex" justify="end">
        <i class="el-icon-s-unfold hidden-sm-and-up" @click="drawer = true" v-show="!drawer"></i>
        <i class="el-icon-s-fold hidden-sm-and-up" @click="drawer = false" v-show="drawer"></i>
      </el-row>
      
      <router-view/>
    </el-main>
  </el-container>

  
</template>
<script>
import { ElMessage } from 'element-plus';
// import axios from './request/req'
// import router from './router'

export default {
  data(){
    return {
      drawer: false
    }
  },
  mounted(){
    // 如果登陆过，检查token是否过期
    this.$store.dispatch('checkToken')
  },
  computed:{
    isLogin(){
      return this.$store.state.isLogin;
    }
  },
  methods:{
    logout(){
      localStorage.clear()
      ElMessage.success({
        type: 'success',
        message:'退出成功'
      })
      // 修改state中isLogin的值
      this.$store.commit('changeLogin',{isLogin:0})
    }
  }
}
</script>
<style>
@media only screen and (max-width: 767px){
  .hidden-xs-only {
    display: none!important;
  }
}

</style>
